import React, { useEffect, useState } from 'react';
import { useSeason } from './SeasonContext';
import HeatmapComponent from './WardHeatmap';
import WardMapComponent from './WardMap';
import LoadingComponent from './base/LoadingComponent';
import axiosInstance from './backend/axiosInstance';

const PlayerHeatmapComponent = ({ name, gameType }) => {
  const { currentSeasonTime } = useSeason();

  document.title = "Heatmap " + name;
  const encodedPlayerName = encodeURIComponent(name);
  const [wardData, setWardData] = useState([]);

  useEffect(() => {
    const [start, end] = currentSeasonTime.split(" - ").map(date => date.trim());
    let apiUrl;
    if (gameType === 'Officials') {
      apiUrl = `playerwards_official/?name=${encodedPlayerName}&start=${start}&end=${end}`;
    } else {
      apiUrl = `playerwards/?name=${encodedPlayerName}`;
    }
    axiosInstance.get(apiUrl)
      .then((response) => {
        setWardData(response.data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, [currentSeasonTime, name, gameType]);

  return (
    <div>
      <div>
        {wardData ? (
          <>
            <div style={{ width: "100%", color: "white", textAlign: "center", alignItems: "center" }}>
              <br></br>
              <h1>WARDS HEATMAP</h1>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                </div>
                <HeatmapComponent wardData={wardData} />
                <div>

                </div>
              </div>
            </div>
          </>
        ) : (
          <LoadingComponent />
        )}
      </div>

      <div>
        {wardData ? (
          <>
            <div style={{ width: "100%", color: "white", textAlign: "center", alignItems: "center" }}>
              <br></br>
              <h1>WARDS MAP</h1>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                </div>
                <WardMapComponent wardData={wardData} />
                <div>

                </div>
              </div>
            </div>
          </>
        ) : (
          <LoadingComponent />
        )}
      </div>
    </div>
  );
};

export default PlayerHeatmapComponent;
