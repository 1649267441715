import React, { useEffect, useState } from 'react';
import { championPhotos } from './data/ChampionsIcon'; // Assuming this is a mapping of champion names to their respective images
import SoloqPageComponent from './SoloqPage';
import CustomSelect from './base/CustomSelect';
import axiosInstance from './backend/axiosInstance';
import summonerSpellDictionary from './data/SummonerSpellData';
import runeIconDictionary from './data/RuneIcons';
import itemIconDictionary from './data/ItemIcons';
import CustomDateInput from './base/CustomInputCalendar';
const getColorBySkillSlot = (skillSlot) => {
  switch (skillSlot) {
    case 1:
      return "#1E90FF"; // Blue for Skill 1
    case 2:
      return "#FF8C00"; // Orange for Skill 2
    case 3:
      return "#8A2BE2"; // Purple for Skill 3
    case 4:
      return "#FF4500"; // Red for Skill 4
    default:
      return "#D3D3D3"; // Gray for anything else
  }
};

const SkillRow = ({ skillName, skillSlot, levelData, championName }) => (
  <div style={{ display: 'flex' }}>
    <div
      style={{
        position: "relative",  // Allows absolute positioning of text inside
        display: "inline-block",  // Ensures the image and text are in a block element
        width: "40px",
        height: "40px",
        fontSize: "12px",  // Adjust font size to fit well on the image
        fontWeight: "bold",
        borderRadius: "5px",
        textAlign: "center",  // Center text horizontally
        color: "white",  // Text color for contrast
      }}
    >
      <img
        style={{
          width: "40px",
          height: "40px",
          borderRadius: "5px",  // Keeps the rounded corners
        }}
        src={`https://cdn.communitydragon.org/latest/champion/${championName}/ability-icon/${skillName.toLowerCase()}`}
        alt={`https://raw.communitydragon.org/pbe/game/assets/characters/${championName.toLowerCase()}/hud/icons2d/${championName.toLowerCase()}_${skillName.toLowerCase()}.png`}
      />
      <div
        style={{
          position: "absolute",  // Overlays the text on top of the image
          top: 0,  // Aligns the text at the top of the image
          left: 0,
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(0, 0, 0, 0.5)",  // Optional: adds a transparent black background for readability
        }}
      >
        {skillName}
      </div>
    </div>

    {levelData.map((level, idx) =>
      level.skillSlot === skillSlot ? (
        <div
          key={idx}
          style={{
            backgroundColor: getColorBySkillSlot(level.skillSlot),
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "40px",
            height: "40px",
            color: "#fff",
            fontSize: "16px",
            fontWeight: "bold",
            borderRadius: "5px",
          }}
        >
          {idx + 1}
        </div>
      ) : (
        <div key={idx} style={{ width: "40px", height: "40px" }}></div>
      )
    )}
  </div>
);


const PlayerSoloqPageComponent = ({ name}) => {

  const [soloQData, setSoloQData] = useState([]);

  const [selectedPatch, setSelectedPatch] = useState([]);
  const [filterPatch, setFilterPatch] = useState([]);
  const [patchOption, setPatchOption] = useState([]);

  const [startDate, setStartDate] = useState(new Date('2024-05-14'));
  const [endDate, setEndDate] = useState(new Date());

  const [hoveredRune, setHoveredRune] = useState({  runeIdx: null }); // Track hovered rune
  const [hoveredLevel, setHoveredLevel] = useState({ levelIdx: null }); // Track hovered rune
  const [hoveredItems, setHoveredItems] = useState({  itemsIdx: null }); // Track hovered rune

  const [levelData, setLevelData] = useState(null);
  const [groupedItems, setGroupedItems] = useState(null);

  const getPlayerNameWithoutTeamTagLower = (fullName) => {
    const parts = fullName.split(' ');
    return parts.slice(1).join(' ').toLowerCase(); // Remove the team tag
  };


  useEffect(() => {
    const playerName = getPlayerNameWithoutTeamTagLower(name);

    axiosInstance.get(`soloQ/?playername=${playerName}&detailed=1`)
      .then(response => {
        const uniquePatches = [
          ...new Set(
            response.data
              .filter((match) => typeof match.patch === 'string' || typeof match.patch === 'number')
              .map((match) => match.patch)
          ),
        ];
        const formattedPatchOptions = uniquePatches.sort().map((patch) => ({
          value: patch,
          label: patch,
        }));
        setPatchOption(formattedPatchOptions);
        const data = response.data.filter(game => {
          const gameDate = new Date(game.date);
          const isPatchGood = filterPatch.length === 0 || filterPatch.includes(game.patch);
          const isDateGood = gameDate >= startDate && gameDate <= endDate;
          return isPatchGood && isDateGood;
        });
        setSoloQData(data)
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });

  }, [name, filterPatch, startDate, endDate]);

  const groupItemsByTime = (items, timeLimit) => {
    const groups = [];
    let currentGroup = [];

    // Sort items by their time property
    const sortedItems = items.sort((a, b) => a.time - b.time);

    sortedItems.forEach((item, idx) => {
      console.log(`Processing item:`, item); // Debug log

      if (currentGroup.length === 0) {
        currentGroup.push(item);
      } else {
        // Check if the current item's time is within the time limit of the last item in the current group
        const lastItemTime = currentGroup[currentGroup.length - 1].time;
        console.log(`Last item time: ${lastItemTime}, Current item time: ${item.time}`); // Debug log

        if (item.time - lastItemTime <= timeLimit) {
          currentGroup.push(item);
        } else {
          groups.push(currentGroup); // Push the current group to the groups array
          console.log(`New group formed:`, currentGroup); // Debug log
          currentGroup = [item]; // Start a new group with the current item
        }
      }
    });

    if (currentGroup.length > 0) {
      groups.push(currentGroup);
      console.log(`Final group added:`, currentGroup); // Debug log
    }

    console.log(`All groups:`, groups); // Debug log
    return groups;
  };

  const handleStartDateChange = (e) => {
    const selectedDate = new Date(e.target.value);
    selectedDate.setHours(0, 0, 0, 0); // Set hours to 00:00:00
    setStartDate(selectedDate);
  };

  const handleEndDateChange = (e) => {
    const selectedDate = new Date(e.target.value);
    selectedDate.setHours(23, 50, 59, 999); // Set hours to 23:59:59
    setEndDate(selectedDate);
  };

  const handlePatchChange = (selectedOptions) => {
    const leagueValues = selectedOptions.map((option) => option.value);
    setFilterPatch(leagueValues);
    setSelectedPatch(selectedOptions);
  };

  const handleMouseEnterLevel = async (game_id, summoner_name, index) => {
    setHoveredLevel({ levelIdx: `${index}` })
    try {
      const response = await axiosInstance.get(`soloQ/abilities/?gameId=${game_id}&player_id=${summoner_name}`, {
      });
      setLevelData(response.data);
    } catch (error) {
      console.error("Error fetching level data:", error);
    }
  };

  const handleMouseEnterItems = async (game_id, summoner_name, index) => {
    setHoveredItems({ itemsIdx: `${index}` })
    try {
      const response = await axiosInstance.get(`soloQ/items/?gameId=${game_id}&player_id=${summoner_name}`, {
      });
      setGroupedItems(groupItemsByTime(response.data, 40));

    } catch (error) {
      console.error("Error fetching level data:", error);
    }
  };


return (
  <div>
    <div className='filters'>
      <div style={{padding: "10px"}}>
        Starting Date
        <CustomDateInput
          value={startDate?.toISOString().split('T')[0]}
          onChange={handleStartDateChange}
          style={{marginLeft: "10px"}}
        />
      </div>
      <div style={{padding: "10px"}}>
        Ending Date
        <CustomDateInput
          value={endDate?.toISOString().split('T')[0]}
          onChange={handleEndDateChange}
          style={{marginLeft: "10px"}}

        />
      </div>

      <div style={{padding: "10px", display: 'flex', alignItems: 'center'}}>
        Patch
        <div className='short-chooser'>
          <CustomSelect options={patchOption} selectedOption={selectedPatch} onOptionChange={handlePatchChange} />
        </div>
      </div>

    </div>
    <div style={{width: "100%", display: 'flex'}}>

      <SoloqPageComponent key={name} name={name} filterPatch={filterPatch} startDate={startDate} endDate={endDate}/>
      <div style={{width: "80%", paddingLeft: "20px"}} >
        {soloQData.length > 0 && soloQData.sort((a, b) => new Date(b.date) - new Date(a.date)).map((game, index) => {
          return (
            <>
            <div  style={{backgroundColor: 'whitesmoke', padding: "10px", marginTop: "20px", justifyContent: "space-between",}}>
              <div key={index} style={{ display: "flex", backgroundColor: 'whitesmoke', padding: "10px", justifyContent: "space-between",}}>
              {/* Date Name */}
                <div style={{ width: "10%", padding: "0 10px" }}>
                  <strong>Date:</strong>
                    {new Date(game.date).toLocaleTimeString('en-GB', { hour: 'numeric', minute: 'numeric' })}
                    <div>
                      {new Date(game.date).toLocaleDateString('en-GB', { year: 'numeric', month: 'numeric', day: 'numeric'})}
                    </div>
                  </div>

                  {/* Patch */}
                  <div style={{ width: "10%", padding: "0 10px" }}>
                    <strong>Patch:</strong>
                    <div>{game.patch}</div>
                  </div>

                        {/* Champions */}
                        <div style={{ width: "80%", padding: "0 10px", display: "flex", flexDirection: "column", gap: "10px" }}>
                          <div
                            key={index}
                            className="champion-details"
                            style={{
                              display: "flex",
                              justifyContent: "space-between", // Distribute items evenly
                              gap: "10px",
                              alignItems: "center",
                              width: "100%", // Ensures the div takes full width
                            }}
                          >
                            <div style={{ flex: 1, display: "flex", alignItems: "center", justifyContent: "center" }}>

                              <strong style={{ flex: 1 }}>
                                {name.split(" ").length > 1 ? name.split(" ").slice(1).join(" ") : name}
                              </strong>
                            </div>

                            {/* Champion Image */}
                            <div style={{ flex: 1, display: "flex", alignItems: "center", justifyContent: "center" }}>
                              <img
                                className="small-image"
                                src={championPhotos[game.champion_name]}
                                alt={game.champion_name}
                                style={{ width: "30px", height: "30px" }}
                              />
                              <span>{game.champion_name}</span>
                            </div>

                            {/* Enemy Champion Image */}
                            <div style={{ flex: 1, display: "flex", alignItems: "center", justifyContent: "center" }}>
                              <img
                                className="small-image"
                                src={championPhotos[game.enemy_champion_name]}
                                alt={game.enemy_champion_name}
                                style={{ width: "30px", height: "30px" }}
                              />
                              {game.enemy_champion_name}
                            </div>

                            {/* Summoner Spells */}
                            <div style={{ flex: 1, display: "flex", alignItems: "center", justifyContent: "center" }}>
                              <img
                                className="small-image"
                                src={summonerSpellDictionary[game.summoner1Id]}
                                alt={game.summoner1Id}
                                style={{ width: "20px", height: "20px" }}
                              />
                              <img
                                className="small-image"
                                src={summonerSpellDictionary[game.summoner2Id]}
                                alt={game.summoner2Id}
                                style={{ width: "20px", height: "20px" }}
                              />
                            </div>

                            {/* KDA */}
                            <span style={{ flex: 1, textAlign: "center" }}>{game.kills}/{game.deaths}/{game.assists}</span>

                            {/* Result */}
                            <span style={{ flex: 1, textAlign: "center" }}>{game.winner === 1 ? "WIN" : "LOSE"}</span>

                            <div style={{ flex: 1, textAlign: "center", position: "relative"  }}
                              onMouseEnter={() => handleMouseEnterLevel(game.gameid, game.player_id, index)}
                              onMouseLeave={() => setHoveredLevel({ levelIdx: null })} // Hide on mouse leave
                            >{game.level}
                              {hoveredLevel.levelIdx === `${index}` && levelData && (
                                <div
                                  style={{
                                    position: "absolute",
                                    top: "35px",
                                    left: "-500px",
                                    gap: "10px",
                                    backgroundColor: "white",
                                    color: 'black',
                                    padding: "10px",
                                    borderRadius: "10px",
                                    border: "1px solid #ddd",
                                    zIndex: 10,
                                  }}
                                >
                                  {['Q', 'W', 'E', 'R'].map((skillName, idx) => (
                                    <SkillRow
                                      key={idx}
                                      skillName={skillName}
                                      skillSlot={idx + 1}
                                      levelData={levelData}
                                      championName={game.champion_name}
                                    />
                                  ))}
                                </div>
                              )}


                            </div>


                            <div
                              style={{ flex: 1, display: "flex", justifyContent: "center", position: "relative" }}
                              onMouseEnter={() => setHoveredRune({ runeIdx: `${index}` })} // Show rune page on hover
                              onMouseLeave={() => setHoveredRune({ runeIdx: null })} // Hide on mouse leave
                            >
                              <img
                                className="medium-image"
                                src={runeIconDictionary["rune" + game.rune0]}
                                alt="rune"
                                style={{ width: "30px", height: "30px" }}
                              />
                              {hoveredRune.runeIdx === `${index}` && (
                                <div
                                  style={{
                                    position: "absolute",
                                    top: "35px",
                                    left: "0",
                                    display: "flex",
                                    gap: "5px",
                                    padding: "10px",
                                    backgroundColor: "#fff",
                                    border: "1px solid #ddd",
                                    borderRadius: "5px",
                                    zIndex: 10,
                                    alignItems: "center",
                                  }}
                                >
                                  {Object.values([game.rune0, game.rune1, game.rune2, game.rune3, game.rune4, game.rune5]).map((runeId, idx) => (
                                    <img
                                      key={idx}
                                      src={runeIconDictionary["rune" + runeId]}
                                      alt={"rune" + runeId}
                                      style={{ width: idx==0 ? "35px" : "25px", height:  idx==0 ? "35px" : "25px" }}
                                    />
                                  ))}
                                </div>
                              )}
                            </div>

                            {/* Items */}
                            <div
                              style={{ flex: 1, display: "flex", justifyContent: "center", position: "relative" }}
                              onMouseEnter={() => handleMouseEnterItems(game.gameid, game.player_id, index)}
                              onMouseLeave={() => setHoveredItems({ itemsIdx: null })} // Hide on mouse leave
                            >
                              {Object.values([game.item0, game.item1, game.item2, game.item3, game.item4, game.item5, game.item6]).map((item_id, idx) => (
                                <img
                                  key={idx}
                                  className="small-image-2"
                                  src={itemIconDictionary["img" + item_id]}
                                  alt={"img" + item_id}
                                  style={{ width: "25px", height: "25px" }}
                                />
                              ))}
                              {hoveredItems.itemsIdx === `${index}` && groupedItems && (
                                <div
                                  style={{
                                    position: "absolute",
                                    top: "35px",
                                    left: "-400px",
                                    display: "flex",
                                    flexWrap: "wrap", // Allow groups to wrap to the next line
                                    gap: "10px", // Space between groups
                                    padding: "10px",
                                    backgroundColor: "#fff",
                                    border: "1px solid #ddd",
                                    borderRadius: "5px",
                                    zIndex: 10,
                                    alignItems: "flex-start", // Align items to the start
                                    maxWidth: "600px", // Set a max width for the tooltip
                                    justifyContent: 'center'
                                  }}
                                >
                                  {groupedItems.map((group, groupIdx) => (
                                    <div key={groupIdx} style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                        {group.map((item, idx) => (
                                          <div key={idx}>
                                            <img
                                              className="small-image-2"
                                              src={itemIconDictionary["img" + item.itemID]}
                                              alt={"img" + item.itemID}
                                              style={{ width: "25px", height: "25px", filter: item.status === "item_sold" ? "grayscale(100%)" : "none" }}
                                            />
                                          </div>
                                        ))}
                                      </div>
                                      <div style={{ textAlign: 'center' }}>
                                        {Math.floor(group[0].time / 60)}min
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              )}
                            </div>

                          </div>
                        </div>

                      </div>
              </div>
            </>
          );
        })}
      </div>
    </div>
  </div>
);
}


export default PlayerSoloqPageComponent;