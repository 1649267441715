import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './Player.css';
import { championPhotos } from "./data/ChampionsIcon";
import CustomSelect from './base/CustomSelect';
import 'react-pivottable/pivottable.css';
import 'rc-slider/assets/index.css';
import ChampionPerformanceComponent from './PlayerChampionsPerformance';
import { useSeason } from './SeasonContext';
import ProximityPlayerComponent from './ProximityPlayer';
import { getGreenCellColor, getRedGreenCell, getRedGreenCellno0, getRedCellColor } from './base/colorUtils';
import axiosInstance from './backend/axiosInstance';

const PlayerPageScrim = () => {
  const {currentSeasonTime } = useSeason();
  const { name } = useParams();
  document.title = "SCRIM " + name;
  const [playerData, setPlayerData] = useState([]);
  const [filterPlayerData, setFilterPlayerData] = useState([]);

  const [proximityData, setProximityData] = useState([]);
  const [filteredProximityData, setFilteredProximityData] = useState([]);

  const encodedPlayerName = encodeURIComponent(name);

  const [sortedColumn, setSortedColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');

  const [selectedPatch, setSelectedPatch] = useState([]);
  const [filterPatch, setFilterPatch] = useState([]);
  const [patchOption, setPatchOption] = useState([]);

  const twoWeeksAgo = new Date();
  twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14);

  // Set the initial state with the calculated date
  const [startDate, setStartDate] = useState(twoWeeksAgo);
  const [endDate, setEndDate] = useState(new Date());

  const [championDictionary, setChampionDictionary] = useState({});

  const [filteredPlayerData, setFilteredPlayerData] = useState({});


  useEffect(() => {
    // Fetch player data based on the player name from the URL
    axiosInstance
      .get(`playersScrims/?name=${encodedPlayerName}`)
      .then(response => {
        const sortedData = response.data.slice().sort((a, b) => b.patch.localeCompare(a.patch));
        setPlayerData(sortedData);
        const uniquePatches = [...new Set(sortedData.map((match) => match.patch))];
        const formattedPatchOptions = uniquePatches.sort().map((patch) => ({
          value: patch,
          label: patch,
        }));
        setPatchOption(formattedPatchOptions);
      })
      .catch(error => {
        console.error(error);
      });

      axiosInstance
      .get(`proximityScrims/?name=${encodedPlayerName}`)
      .then(response => {
        const sortedData = response.data.slice().sort((a, b) => b.patch.localeCompare(a.patch));
        setProximityData(sortedData);
      })
      .catch(error => {
        console.error(error);
      });

    }, []);

    useEffect(() => {
      if (playerData) {
        const filteredPlayersPick = playerData.filter((player) => {
          const isPatchGood = filterPatch.length === 0 || filterPatch.includes(player.patch);
          const matchDate = new Date(player.date);
          return (!startDate || matchDate >= startDate) && (!endDate || matchDate <= endDate) && isPatchGood;
        });
        setFilteredPlayerData(filteredPlayersPick);
        const newChampionDictionary = {};

        filteredPlayersPick.forEach((player) => {
          const championName = player.championName;

          if (!newChampionDictionary[championName]) {
            newChampionDictionary[championName] = {
              matchesPlayed: 0,
              totalWins: 0,
            };
          }

          newChampionDictionary[championName].matchesPlayed += 1;
          newChampionDictionary[championName].totalWins += player.winner;
        });

        // Calculate win rates and sort the champion data
        for (const championName in newChampionDictionary) {
          const champion = newChampionDictionary[championName];
          champion.winRate = ((champion.totalWins * 100) / champion.matchesPlayed).toFixed(2);
        }

        const sortedChampions = Object.entries(newChampionDictionary).sort(
          (a, b) => b[1].matchesPlayed - a[1].matchesPlayed
        );

        // Map the sorted entries back to an object
        const sortedChampionObject = Object.fromEntries(sortedChampions);

        setFilterPlayerData(filteredPlayersPick);
        setChampionDictionary(sortedChampionObject);
      }
    }, [playerData, filterPatch, startDate, endDate]);

    useEffect(() => {
      if (playerData) {
        const filteredPlayersPick = playerData.filter((player) => {
          const isPatchGood = filterPatch.length === 0 || filterPatch.includes(player.patch);
          const matchDate = new Date(player.date);
          return (!startDate || matchDate >= startDate) && (!endDate || matchDate <= endDate) &&  isPatchGood;
        });
      }
      if (proximityData) {
        const filteredProximityData1 = proximityData.filter((player) => {
          const isPatchGood = filterPatch.length === 0 || filterPatch.includes(player.patch);
          return isPatchGood;
        });
        setFilteredProximityData(filteredProximityData1);
      }
    }, [playerData, filterPatch, startDate, endDate, proximityData]);

  const handlePatchChange = (selectedOptions) => {
    //setMatchesForCurrentPage([]);
    const leagueValues = selectedOptions.map((option) => option.value);
    setFilterPatch(leagueValues);
    setSelectedPatch(selectedOptions);
  };

  const handleSort = (columnName) => {
    if (sortedColumn === columnName) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortedColumn(columnName);
      setSortDirection('asc');
    }
  };

  const handleStartDateChange = (e) => {
    const selectedDate = new Date(e.target.value);
    selectedDate.setHours(0, 0, 0, 0); // Set hours to 00:00:00
    setStartDate(selectedDate);
  };

  const handleEndDateChange = (e) => {
    const selectedDate = new Date(e.target.value);
    selectedDate.setHours(23, 50, 59, 999); // Set hours to 23:59:59
    setEndDate(selectedDate);
  };

  function calculateAverageDamagePerMinute(player) {
    return (player.DamageDealt / player.gameDuration).toFixed(0);
  }

  function calculateAverageCSPerMinute(player) {
    return (player.CS / player.gameDuration).toFixed(2);
  }

  function calculateAverageGoldPerMinute(player) {
    return (player.GoldEarned / player.gameDuration).toFixed(0);
  }

  const sortedFilterPlayerData = filterPlayerData.slice().sort((a, b) => {
    a.averageCSPerMinute = calculateAverageCSPerMinute(a);
    b.averageCSPerMinute = calculateAverageCSPerMinute(b);

    a.averageGoldPerMinute = calculateAverageGoldPerMinute(a);
    b.averageGoldPerMinute = calculateAverageGoldPerMinute(b);
    if (sortedColumn === 'damageShared' || sortedColumn === 'goldShared' || sortedColumn === 'JungleProximity') {
      // Handle sorting for percentage columns
      const aValue = a[sortedColumn] * 100;
      const bValue = b[sortedColumn] * 100;
      return sortDirection === 'asc' ? aValue - bValue : bValue - aValue;
    } else if (sortedColumn === 'DamageDealt')
    {
      const aValue = calculateAverageDamagePerMinute(a);
      const bValue = calculateAverageDamagePerMinute(b);
      return sortDirection === 'asc' ? aValue - bValue : bValue - aValue;
    }
    else if (sortedColumn === 'CS') {
    return sortDirection === 'asc'
      ? parseFloat(a.averageCSPerMinute) - parseFloat(b.averageCSPerMinute)
      : parseFloat(b.averageCSPerMinute) - parseFloat(a.averageCSPerMinute);
    } else if (sortedColumn === 'GoldEarned') {
    return sortDirection === 'asc'
      ? parseFloat(a.averageGoldPerMinute) - parseFloat(b.averageGoldPerMinute)
      : parseFloat(b.averageGoldPerMinute) - parseFloat(a.averageGoldPerMinute);
   }
    else if (sortedColumn === 'championName' || sortedColumn === 'enemyChampionName') {

      const aValue = a[sortedColumn].toUpperCase(); // Convert to uppercase for case-insensitive sorting
      const bValue = b[sortedColumn].toUpperCase(); // Convert to uppercase for case-insensitive sorting
      return sortDirection === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
    }
    else
    {
      // Handle sorting for other columns
      return sortDirection === 'asc'
        ? a[sortedColumn] - b[sortedColumn]
        : b[sortedColumn] - a[sortedColumn];
    }
  });

  function averageValue(columnName) {
    const total = filterPlayerData.reduce((acc, record) => acc + record[columnName], 0);

    return (total / filterPlayerData.length); // You can adjust the number of decimal places
  }

  const getMaxValueDivided = (property, divisor) => {
    return Math.max(
      ...filterPlayerData
        .map((player) => player[property] / player[divisor])
        .map((value) => parseFloat(value))
    );
  };

  const getMinValueDivided = (property, divisor) => {
    return Math.min(
      ...filterPlayerData
        .map((player) => player[property] / player[divisor])
        .map((value) => parseFloat(value))
    );
  };

  const getMaxValue = (property) => {
    return Math.max(...Object.values(filterPlayerData).map((player) => player[property]));
  };

  const getMinValue = (property) => {
    return Math.min(...Object.values(filterPlayerData).map((player) => player[property]));
  };

  const handleGameClick = (teamName) => {
    window.location.href = `/scrim/${teamName}`;
  };

  return (
    <div className='main' >
      <div className='filters'>
        Starting Date
        <input
          type="date"
          value={startDate?.toISOString().split('T')[0]}
          onChange={handleStartDateChange}
        />
        Ending Date
        <input
          type="date"
          value={endDate?.toISOString().split('T')[0]}
          onChange={handleEndDateChange}
        />
        Patch
        <div className='short-chooser'>
          <CustomSelect options={patchOption} selectedOption={selectedPatch} onOptionChange={handlePatchChange} />
        </div>

      </div>

      <div style={{backgroundColor: 'white'}}>
      {filterPlayerData ? (
        <div className='team-performance-table-container'>
          <table className='team-performance-table'>
            <thead>
              <tr>
                <th onClick={() => handleSort('championName')}>championName</th>
                <th onClick={() => handleSort('enemyChampionName')}>enemyName</th>
                <th onClick={() => handleSort('winner')}>WR%</th>
                <th onClick={() => handleSort('killsAndAssists8')}>K&A@8</th>
                <th onClick={() => handleSort('deaths8')}>D@8</th>
                <th onClick={() => handleSort('goldDiff8')}>GD@8</th>
                <th onClick={() => handleSort('csDiff8')}>CSD@8</th>
                <th onClick={() => handleSort('expDiff8')}>XPD@8</th>
                <th onClick={() => handleSort('killsAndAssists14')}>K&A@14</th>
                <th onClick={() => handleSort('deaths14')}>D@14</th>
                <th onClick={() => handleSort('goldDiff14')}>GD@14</th>
                <th onClick={() => handleSort('csDiff14')}>CSD@14</th>
                <th onClick={() => handleSort('expDiff14')}>XPD@14</th>
                <th onClick={() => handleSort('damageShared')}>DMG%</th>
                <th onClick={() => handleSort('goldShared')}>G%</th>
                <th onClick={() => handleSort('JungleProximity')}>JGL Prox</th>
                <th onClick={() => handleSort('kills')}>K</th>
                <th onClick={() => handleSort('deaths')}>D</th>
                <th onClick={() => handleSort('assists')}>A</th>
                <th onClick={() => handleSort('DamageDealt')}>DMG/M</th>
                <th onClick={() => handleSort('GoldEarned')}>G/M</th>
                <th onClick={() => handleSort('CS')}>CS/M</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {sortedFilterPlayerData.map((record, index) => {

                  const damagePerMinute = (record.DamageDealt / record.gameDuration).toFixed(0);
                  const csPerMinute = (record.CS / record.gameDuration).toFixed(2);
                  const goldPerMinute = (record.GoldEarned / record.gameDuration).toFixed(0);
                return (
                  <tr key={index}>
                    <td style={{ textAlign: 'left', justifyContent: 'left' }}><img className='small-image' src={championPhotos[record.championName]} alt="" />{record.championName}</td>
                    <td style={{ textAlign: 'left' }}><img className='small-image' src={championPhotos[record.enemyChampionName]} alt="" />{record.enemyChampionName}</td>
                    <td>{record.winner ? 'Yes' : 'No'}</td>
                    <td style={{ backgroundColor: getGreenCellColor(record.killsAndAssists8, getMaxValue('killsAndAssists8')) }}>{record.killsAndAssists8.toFixed(2)}</td>
                    <td style={{ backgroundColor: getRedCellColor(record.deaths8, getMaxValue('deaths8')) }}>{record.deaths8.toFixed(2)}</td>
                    <td style={{ backgroundColor: getRedGreenCell(record.goldDiff8, getMinValue('goldDiff8'), getMaxValue('goldDiff8'))}}>{record.goldDiff8.toFixed(0)}</td>
                    <td style={{ backgroundColor: getRedGreenCell(record.csDiff8, getMinValue('csDiff8'), getMaxValue('csDiff8'))}}>{record.csDiff8.toFixed(0)}</td>
                    <td style={{ backgroundColor: getRedGreenCell(record.expDiff8, getMinValue('expDiff8'), getMaxValue('expDiff8'))}}>{record.expDiff8.toFixed(0)}</td>
                    <td style={{ backgroundColor: getGreenCellColor(record.killsAndAssists14, getMaxValue('killsAndAssists14')) }}>{record.killsAndAssists14.toFixed(2)}</td>
                    <td style={{ backgroundColor: getRedCellColor(record.deaths14, getMaxValue('deaths14')) }}>{record.deaths14.toFixed(2)}</td>
                    <td style={{ backgroundColor: getRedGreenCell(record.goldDiff14, getMinValue('goldDiff14'), getMaxValue('goldDiff14'))}}>{record.goldDiff14.toFixed(0)}</td>
                    <td style={{ backgroundColor: getRedGreenCell(record.csDiff14, getMinValue('csDiff14'), getMaxValue('csDiff14'))}}>{record.csDiff14.toFixed(0)}</td>
                    <td style={{ backgroundColor: getRedGreenCell(record.expDiff14, getMinValue('expDiff14'), getMaxValue('expDiff14'))}}>{record.expDiff14.toFixed(0)}</td>
                    <td style={{ backgroundColor: getRedGreenCellno0(record.damageShared, getMinValue('damageShared'), getMaxValue('damageShared'))}}>{(record.damageShared*100).toFixed(2)}%</td>
                    <td style={{ backgroundColor: getRedGreenCellno0(record.goldShared, getMinValue('goldShared'), getMaxValue('goldShared'))}}>{(record.goldShared*100).toFixed(2)}%</td>
                    <td style={{ backgroundColor: getRedGreenCellno0(record.JungleProximity, getMinValue('JungleProximity'), getMaxValue('JungleProximity'))}}>{(record.JungleProximity*100).toFixed(2)}%</td>
                    <td style={{ backgroundColor: getGreenCellColor(record.kills, getMaxValue('kills')) }}>{record.kills}</td>
                    <td style={{ backgroundColor: getRedCellColor(record.deaths, getMaxValue('deaths')) }}>{record.deaths}</td>
                    <td style={{ backgroundColor: getGreenCellColor(record.assists, getMaxValue('assists')) }}>{record.assists}</td>
                    <td style={{ backgroundColor: getRedGreenCellno0(damagePerMinute, getMinValueDivided('DamageDealt','gameDuration'), getMaxValueDivided('DamageDealt','gameDuration'))}}>{(damagePerMinute)}</td>
                    <td style={{ backgroundColor: getRedGreenCellno0(goldPerMinute, getMinValueDivided('GoldEarned', 'gameDuration'), getMaxValueDivided('GoldEarned', 'gameDuration'))}}>{(goldPerMinute)}</td>
                    <td style={{ backgroundColor: getRedGreenCellno0(csPerMinute, getMinValueDivided('CS', 'gameDuration'), getMaxValueDivided('CS', 'gameDuration'))}}>{csPerMinute}</td>
                    <td style={{ cursor: 'pointer' }} onClick={() => handleGameClick(record.scrimId)}>{"->"}</td>
                  </tr>
                );
              })}
            </tbody>
            <tfoot>
              <tr style={{backgroundColor: "white"}}>
                <td>Average</td>
                <td></td>
                <td></td>
                <td>{averageValue('killsAndAssists8')}</td>
                <td>{averageValue('deaths8')}</td>
                <td>{(averageValue('goldDiff8')*1).toFixed(0)}</td>
                <td>{(averageValue('csDiff8')*1).toFixed(0)}</td>
                <td>{(averageValue('expDiff8')*1).toFixed(0)}</td>
                <td>{averageValue('killsAndAssists14')}</td>
                <td>{averageValue('deaths14')}</td>
                <td>{(averageValue('goldDiff14')*1).toFixed(0)}</td>
                <td>{(averageValue('csDiff14')*1).toFixed(0)}</td>
                <td>{(averageValue('expDiff14')*1).toFixed(0)}</td>
                <td>{(averageValue('damageShared') * 100).toFixed(2)}%</td>
                <td>{(averageValue('goldShared') * 100).toFixed(2)}%</td>
                <td>{(averageValue('JungleProximity') * 100).toFixed(2)}%</td>
                <td>{averageValue('kills')}</td>
                <td>{averageValue('deaths')}</td>
                <td>{averageValue('assists')}</td>
                <td>{(averageValue('DamageDealt') / averageValue('gameDuration')).toFixed(0)}</td>
                <td>{(averageValue('GoldEarned') / averageValue('gameDuration')).toFixed(0)}</td>
                <td>{(averageValue('CS') / averageValue('gameDuration')).toFixed(2)}</td>
                <td></td>
              </tr>
            </tfoot>
          </table>
          </div>

        ) : (
          <p>Loading...</p>
        )}

      </div>

      <div>
        { championDictionary && (
        <table style={{backgroundColor: "white"}}>
         <thead>
            <tr>
              <th></th>
              <th>Champion</th>
              <th>Games</th>
              <th>WR</th>
            </tr>
          </thead>
            <tbody>
              {Object.entries(championDictionary).map(([championName, championData], champIndex) => (
                <tr key={champIndex}>
                  <td><img className="small-image" src={championPhotos[championName]} alt='' /></td>
                  <td>{championName}</td>
                  <td>{championData.matchesPlayed}</td>
                  <td>{championData.winRate}%</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>

      <div style={{width: "100%"}}>
        <ChampionPerformanceComponent playerData={filterPlayerData}/>
      </div>

      {filteredProximityData && filteredProximityData.length > 0 && (
        <ProximityPlayerComponent proximityData={filteredProximityData} />
      )}
    </div>
  );
};
export default PlayerPageScrim;
