import Aatrox from "../../champion_photos/Aatrox.png";
import Ahri from "../../champion_photos/Ahri.png";
import Akali from "../../champion_photos/Akali.png";
import Akshan from "../../champion_photos/Akshan.png";
import Alistar from "../../champion_photos/Alistar.png";
import Amumu from "../../champion_photos/Amumu.png";
import Anivia from "../../champion_photos/Anivia.png";
import Annie from "../../champion_photos/Annie.png";
import Aphelios from "../../champion_photos/Aphelios.png";
import Ashe from "../../champion_photos/Ashe.png";
import AurelionSol from "../../champion_photos/AurelionSol.png";
import Azir from "../../champion_photos/Azir.png";
import Bard from "../../champion_photos/Bard.png";
import Belveth from "../../champion_photos/Belveth.png";
import Blitzcrank from "../../champion_photos/Blitzcrank.png";
import Brand from "../../champion_photos/Brand.png";
import Braum from "../../champion_photos/Braum.png";
import Briar from "../../champion_photos/Briar.png";
import Caitlyn from "../../champion_photos/Caitlyn.png";
import Camille from "../../champion_photos/Camille.png";
import Cassiopeia from "../../champion_photos/Cassiopeia.png";
import Chogath from "../../champion_photos/Chogath.png";
import Corki from "../../champion_photos/Corki.png";
import Darius from "../../champion_photos/Darius.png";
import Diana from "../../champion_photos/Diana.png";
import Draven from "../../champion_photos/Draven.png";
import DrMundo from "../../champion_photos/DrMundo.png";
import Ekko from "../../champion_photos/Ekko.png";
import Elise from "../../champion_photos/Elise.png";
import Evelynn from "../../champion_photos/Evelynn.png";
import Ezreal from "../../champion_photos/Ezreal.png";
import FiddleSticks from "../../champion_photos/Fiddlesticks.png";
import Fiora from "../../champion_photos/Fiora.png";
import Fizz from "../../champion_photos/Fizz.png";
import Galio from "../../champion_photos/Galio.png";
import Gangplank from "../../champion_photos/Gangplank.png";
import Garen from "../../champion_photos/Garen.png";
import Gnar from "../../champion_photos/Gnar.png";
import Gragas from "../../champion_photos/Gragas.png";
import Graves from "../../champion_photos/Graves.png";
import Gwen from "../../champion_photos/Gwen.png";
import Hecarim from "../../champion_photos/Hecarim.png";
import Heimerdinger from "../../champion_photos/Heimerdinger.png";
import Illaoi from "../../champion_photos/Illaoi.png";
import Irelia from "../../champion_photos/Irelia.png";
import Ivern from "../../champion_photos/Ivern.png";
import Janna from "../../champion_photos/Janna.png";
import JarvanIV from "../../champion_photos/JarvanIV.png";
import Jax from "../../champion_photos/Jax.png";
import Jayce from "../../champion_photos/Jayce.png";
import Jhin from "../../champion_photos/Jhin.png";
import Jinx from "../../champion_photos/Jinx.png";
import Kaisa from "../../champion_photos/Kaisa.png";
import Kalista from "../../champion_photos/Kalista.png";
import Karma from "../../champion_photos/Karma.png";
import Karthus from "../../champion_photos/Karthus.png";
import Kassadin from "../../champion_photos/Kassadin.png";
import Katarina from "../../champion_photos/Katarina.png";
import Kayle from "../../champion_photos/Kayle.png";
import Kayn from "../../champion_photos/Kayn.png";
import Kennen from "../../champion_photos/Kennen.png";
import Khazix from "../../champion_photos/Khazix.png";
import Kindred from "../../champion_photos/Kindred.png";
import Kled from "../../champion_photos/Kled.png";
import KogMaw from "../../champion_photos/KogMaw.png";
import KSante from "../../champion_photos/KSante.png";
import Leblanc from "../../champion_photos/Leblanc.png";
import LeeSin from "../../champion_photos/LeeSin.png";
import Leona from "../../champion_photos/Leona.png";
import Lillia from "../../champion_photos/Lillia.png";
import Lissandra from "../../champion_photos/Lissandra.png";
import Lucian from "../../champion_photos/Lucian.png";
import Lulu from "../../champion_photos/Lulu.png";
import Lux from "../../champion_photos/Lux.png";
import Malphite from "../../champion_photos/Malphite.png";
import Malzahar from "../../champion_photos/Malzahar.png";
import Maokai from "../../champion_photos/Maokai.png";
import MasterYi from "../../champion_photos/MasterYi.png";
import MissFortune from "../../champion_photos/MissFortune.png";
import MonkeyKing from "../../champion_photos/MonkeyKing.png";
import Mordekaiser from "../../champion_photos/Mordekaiser.png";
import Morgana from "../../champion_photos/Morgana.png";
import Nami from "../../champion_photos/Nami.png";
import Nasus from "../../champion_photos/Nasus.png";
import Nautilus from "../../champion_photos/Nautilus.png";
import Neeko from "../../champion_photos/Neeko.png";
import Nidalee from "../../champion_photos/Nidalee.png";
import Nilah from "../../champion_photos/Nilah.png";
import Nocturne from "../../champion_photos/Nocturne.png";
import Nunu from "../../champion_photos/Nunu.png";
import Olaf from "../../champion_photos/Olaf.png";
import Orianna from "../../champion_photos/Orianna.png";
import Ornn from "../../champion_photos/Ornn.png";
import Pantheon from "../../champion_photos/Pantheon.png";
import Poppy from "../../champion_photos/Poppy.png";
import Pyke from "../../champion_photos/Pyke.png";
import Qiyana from "../../champion_photos/Qiyana.png";
import Quinn from "../../champion_photos/Quinn.png";
import Rakan from "../../champion_photos/Rakan.png";
import Rammus from "../../champion_photos/Rammus.png";
import RekSai from "../../champion_photos/RekSai.png";
import Rell from "../../champion_photos/Rell.png";
import Renata from "../../champion_photos/Renata.png";
import Renekton from "../../champion_photos/Renekton.png";
import Rengar from "../../champion_photos/Rengar.png";
import Riven from "../../champion_photos/Riven.png";
import Rumble from "../../champion_photos/Rumble.png";
import Ryze from "../../champion_photos/Ryze.png";
import Samira from "../../champion_photos/Samira.png";
import Sejuani from "../../champion_photos/Sejuani.png";
import Senna from "../../champion_photos/Senna.png";
import Seraphine from "../../champion_photos/Seraphine.png";
import Sett from "../../champion_photos/Sett.png";
import Shaco from "../../champion_photos/Shaco.png";
import Shen from "../../champion_photos/Shen.png";
import Shyvana from "../../champion_photos/Shyvana.png";
import Singed from "../../champion_photos/Singed.png";
import Sion from "../../champion_photos/Sion.png";
import Sivir from "../../champion_photos/Sivir.png";
import Skarner from "../../champion_photos/Skarner.png";
import Sona from "../../champion_photos/Sona.png";
import Soraka from "../../champion_photos/Soraka.png";
import Swain from "../../champion_photos/Swain.png";
import Sylas from "../../champion_photos/Sylas.png";
import Syndra from "../../champion_photos/Syndra.png";
import TahmKench from "../../champion_photos/TahmKench.png";
import Taliyah from "../../champion_photos/Taliyah.png";
import Talon from "../../champion_photos/Talon.png";
import Taric from "../../champion_photos/Taric.png";
import Teemo from "../../champion_photos/Teemo.png";
import Thresh from "../../champion_photos/Thresh.png";
import Tristana from "../../champion_photos/Tristana.png";
import Trundle from "../../champion_photos/Trundle.png";
import Tryndamere from "../../champion_photos/Tryndamere.png";
import TwistedFate from "../../champion_photos/TwistedFate.png";
import Twitch from "../../champion_photos/Twitch.png";
import Udyr from "../../champion_photos/Udyr.png";
import Urgot from "../../champion_photos/Urgot.png";
import Varus from "../../champion_photos/Varus.png";
import Vayne from "../../champion_photos/Vayne.png";
import Veigar from "../../champion_photos/Veigar.png";
import Velkoz from "../../champion_photos/Velkoz.png";
import Vex from "../../champion_photos/Vex.png";
import Vi from "../../champion_photos/Vi.png";
import Viego from "../../champion_photos/Viego.png";
import Viktor from "../../champion_photos/Viktor.png";
import Vladimir from "../../champion_photos/Vladimir.png";
import Volibear from "../../champion_photos/Volibear.png";
import Warwick from "../../champion_photos/Warwick.png";
import Xayah from "../../champion_photos/Xayah.png";
import Xerath from "../../champion_photos/Xerath.png";
import XinZhao from "../../champion_photos/XinZhao.png";
import Yasuo from "../../champion_photos/Yasuo.png";
import Yone from "../../champion_photos/Yone.png";
import Yorick from "../../champion_photos/Yorick.png";
import Yuumi from "../../champion_photos/Yuumi.png";
import Zac from "../../champion_photos/Zac.png";
import Zed from "../../champion_photos/Zed.png";
import Zeri from "../../champion_photos/Zeri.png";
import Ziggs from "../../champion_photos/Ziggs.png";
import Zilean from "../../champion_photos/Zilean.png";
import Zoe from "../../champion_photos/Zoe.png";
import Zyra from "../../champion_photos/Zyra.png";
import Milio from "../../champion_photos/Milio.png";
import Naafiri from "../../champion_photos/Naafiri.png";
import Hwei from "../../champion_photos/Hwei.png";
import Smolder from "../../champion_photos/Smolder.png";
import Aurora from "../../champion_photos/Aurora.png";

import role0Icon from '../../role_photos/0.png';
import role1Icon from '../../role_photos/1.png';
import role2Icon from '../../role_photos/2.png';
import role3Icon from '../../role_photos/3.png';
import role4Icon from '../../role_photos/4.png';
export const championPhotos = {
  TOP: role0Icon,
  JGL: role1Icon,
  JNG: role1Icon,
  MID: role2Icon,
  ADC: role3Icon,
  SUP: role4Icon,
  Smolder,
  Aurora,
  Briar,
  Hwei,
  Aatrox,
  Ahri,
  Akali,
  Akshan,
  Alistar,
  Amumu,
  Anivia,
  Annie,
  Aphelios,
  Ashe,
  AurelionSol,
  Azir,
  Bard,
  Belveth,
  Blitzcrank,
  Brand,
  Braum,
  Caitlyn,
  Camille,
  Cassiopeia,
  Chogath,
  Corki,
  Darius,
  Diana,
  Draven,
  DrMundo,
  Ekko,
  Elise,
  Evelynn,
  Ezreal,
  FiddleSticks,
  Fiora,
  Fizz,
  Galio,
  Gangplank,
  Garen,
  Gnar,
  Gragas,
  Graves,
  Gwen,
  Hecarim,
  Heimerdinger,
  Illaoi,
  Irelia,
  Ivern,
  Janna,
  JarvanIV,
  Jax,
  Jayce,
  Jhin,
  Jinx,
  Kaisa,
  Kalista,
  Karma,
  Karthus,
  Kassadin,
  Katarina,
  Kayle,
  Kayn,
  Kennen,
  Khazix,
  Kindred,
  Kled,
  KogMaw,
  KSante,
  Leblanc,
  LeeSin,
  Leona,
  Lillia,
  Lissandra,
  Lucian,
  Lulu,
  Lux,
  Malphite,
  Malzahar,
  Maokai,
  MasterYi,
  MissFortune,
  MonkeyKing,
  Mordekaiser,
  Morgana,
  Nami,
  Nasus,
  Nautilus,
  Neeko,
  Nidalee,
  Nilah,
  Nocturne,
  Nunu,
  Olaf,
  Orianna,
  Ornn,
  Pantheon,
  Poppy,
  Pyke,
  Qiyana,
  Quinn,
  Rakan,
  Rammus,
  RekSai,
  Rell,
  Renata,
  Renekton,
  Rengar,
  Riven,
  Rumble,
  Ryze,
  Samira,
  Sejuani,
  Senna,
  Seraphine,
  Sett,
  Shaco,
  Shen,
  Shyvana,
  Singed,
  Sion,
  Sivir,
  Skarner,
  Sona,
  Soraka,
  Swain,
  Sylas,
  Syndra,
  TahmKench,
  Taliyah,
  Talon,
  Taric,
  Teemo,
  Thresh,
  Tristana,
  Trundle,
  Tryndamere,
  TwistedFate,
  Twitch,
  Udyr,
  Urgot,
  Varus,
  Vayne,
  Veigar,
  Velkoz,
  Vex,
  Vi,
  Viego,
  Viktor,
  Vladimir,
  Volibear,
  Warwick,
  Xayah,
  Xerath,
  XinZhao,
  Yasuo,
  Yone,
  Yorick,
  Yuumi,
  Zac,
  Zed,
  Zeri,
  Ziggs,
  Zilean,
  Zoe,
  Zyra,
  Milio,
  Naafiri,
  Wukong:MonkeyKing
    // Add more photo properties as needed
  };

export const championData = {
    901: "Smolder",
    233: "Briar",
    266: "Aatrox",
    910: "Hwei",
    103: "Ahri",
    84: "Akali",
    166: "Akshan",
    12: "Alistar",
    32: "Amumu",
    34: "Anivia",
    1: "Annie",
    523: "Aphelios",
    22: "Ashe",
    136: "AurelionSol",
    268: "Azir",
    432: "Bard",
    200: "Belveth",
    53: "Blitzcrank",
    63: "Brand",
    201: "Braum",
    51: "Caitlyn",
    164: "Camille",
    69: "Cassiopeia",
    31: "Chogath",
    42: "Corki",
    122: "Darius",
    131: "Diana",
    36: "DrMundo",
    119: "Draven",
    245: "Ekko",
    60: "Elise",
    28: "Evelynn",
    81: "Ezreal",
    9: "FiddleSticks",
    114: "Fiora",
    105: "Fizz",
    3: "Galio",
    41: "Gangplank",
    86: "Garen",
    150: "Gnar",
    79: "Gragas",
    104: "Graves",
    887: "Gwen",
    120: "Hecarim",
    74: "Heimerdinger",
    420: "Illaoi",
    39: "Irelia",
    427: "Ivern",
    40: "Janna",
    59: "JarvanIV",
    24: "Jax",
    126: "Jayce",
    202: "Jhin",
    222: "Jinx",
    145: "Kaisa",
    429: "Kalista",
    43: "Karma",
    30: "Karthus",
    38: "Kassadin",
    55: "Katarina",
    10: "Kayle",
    141: "Kayn",
    85: "Kennen",
    121: "Khazix",
    203: "Kindred",
    240: "Kled",
    96: "KogMaw",
    897: "KSante",
    7: "Leblanc",
    64: "LeeSin",
    89: "Leona",
    876: "Lillia",
    127: "Lissandra",
    236: "Lucian",
    117: "Lulu",
    99: "Lux",
    54: "Malphite",
    90: "Malzahar",
    57: "Maokai",
    11: "MasterYi",
    21: "MissFortune",
    902: "Milio",
    82: "Mordekaiser",
    25: "Morgana",
    267: "Nami",
    75: "Nasus",
    111: "Nautilus",
    518: "Neeko",
    76: "Nidalee",
    895: "Nilah",
    56: "Nocturne",
    20: "Nunu",
    2: "Olaf",
    61: "Orianna",
    516: "Ornn",
    80: "Pantheon",
    78: "Poppy",
    555: "Pyke",
    246: "Qiyana",
    133: "Quinn",
    497: "Rakan",
    33: "Rammus",
    421: "RekSai",
    526: "Rell",
    888: "Renata",
    58: "Renekton",
    107: "Rengar",
    92: "Riven",
    68: "Rumble",
    13: "Ryze",
    360: "Samira",
    113: "Sejuani",
    235: "Senna",
    147: "Seraphine",
    875: "Sett",
    35: "Shaco",
    98: "Shen",
    102: "Shyvana",
    27: "Singed",
    14: "Sion",
    15: "Sivir",
    72: "Skarner",
    37: "Sona",
    16: "Soraka",
    50: "Swain",
    517: "Sylas",
    134: "Syndra",
    223: "TahmKench",
    163: "Taliyah",
    91: "Talon",
    44: "Taric",
    17: "Teemo",
    412: "Thresh",
    18: "Tristana",
    48: "Trundle",
    23: "Tryndamere",
    4: "TwistedFate",
    29: "Twitch",
    77: "Udyr",
    6: "Urgot",
    110: "Varus",
    67: "Vayne",
    45: "Veigar",
    161: "Velkoz",
    711: "Vex",
    254: "Vi",
    234: "Viego",
    112: "Viktor",
    8: "Vladimir",
    106: "Volibear",
    19: "Warwick",
    62: "MonkeyKing",
    498: "Xayah",
    101: "Xerath",
    5: "XinZhao",
    157: "Yasuo",
    777: "Yone",
    83: "Yorick",
    350: "Yuumi",
    154: "Zac",
    238: "Zed",
    221: "Zeri",
    115: "Ziggs",
    26: "Zilean",
    142: "Zoe",
    143: "Zyra",
    950: "Naafiri",
    893: "Aurora"
  };

