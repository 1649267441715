import SummonersRift from '../../maps/SummonersRift.png';
import RedTurret from "../../maps/Red_Turret_icon.webp";
import BlueTurret from "../../maps/Blue_Turret_icon.webp";
import creep from "../../maps/creep.png";
import gold from "../../maps/gold.png";
import nashor from "../../maps/nashor.png";
import tower from "../../maps/tower.png";
import rift_herald from "../../maps/rift_herald.png";
import baron_red from "../../maps/Baron_Nashor_icon_(Red).webp";
import baron_blue from "../../maps/Baron_Nashor_icon_(Blue).webp";
import dragon_blue from "../../maps/Dragon_icon_29.webp";
import dragon_red from "../../maps/Dragon_icon_29_Red.webp";
import herald_blue from "../../maps/Rift_Herald_map_29.webp";
import herald_red from "../../maps/Rift_Herald_map_(Red).webp";
import ward from "../../maps/Ward_icon.webp";
import kill from "../../maps/skull.png";
import VoidGrub from "../../maps/newmoon.png";
import camp from "../../maps/camp.png";
import questionMark from "../../maps/question-sign.png";
import close from "../../maps/close.png";
import swords from "../../maps/swords.png";
import wardYes from "../../maps/1.png";
import wardNo from "../../maps/2.png";
import proximityYes from "../../maps/3.png";
import proximityNo from "../../maps/4.png";
import iconsYes from "../../maps/5.png";
import ice from "../../maps/ice.png";

const mapPhotsDictionary = {
    wardYes, wardNo, proximityYes, proximityNo, iconsYes, ice,
    close,
    swords,
    questionMark,
    camp,
    kill,
    VoidGrub,
    SummonersRift,
    RedTurret,
    BlueTurret,
    creep,
    gold,
    nashor,
    tower,
    riftHerald:rift_herald,
    rift_herald,
    baron_red,
    baron_blue,
    dragon_red,
    dragon_blue,
    herald_red,
    herald_blue,
    dragon:dragon_red,
    baron:baron_blue,
    ward,
}

export default mapPhotsDictionary;