import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import PlayerPage from './Player';
import axiosInstance from './backend/axiosInstance';
import { useSeason } from './SeasonContext';
import { TeamIcons } from './data/TeamIcons';
import roleIcon from './data/Role'; // Adjust the path accordingly
import PlayerPageScrim from "./PlayerScrim";
import PlayerHeatmapComponent from './PlayerWard';
import PlayerSoloqPageComponent from './PlayerSoloQPage';
import PlayerPositionTestComponent from './PlayerPositionTest';

const MasterPlayerComponent = () => {
  const { currentSeasonTime } = useSeason();

  const { name } = useParams();
  document.title = name;

  const [activeBigTab, setActiveBigTab] = useState('officials');
  const [activeSubTab, setActiveSubTab] = useState('playerPage');
  const [teamMatesData, setTeamMatesData] = useState(null);

  const Bigtabs = [
    { title: "Officials", id: 'officials' },
    { title: "Scrims", id: 'scrims' },
    { title: "SoloQ", id: 'soloQ' },
  ];

  const officialsTabs = [
    { title: "Player stats", id: 'playerPage', component: <PlayerPage name={name} /> },
    { title: "Wards", id: 'playerWards', component: <PlayerHeatmapComponent name={name} gameType="Officials"/> },
    { title: "Player Position", id: 'playerposition', component: <PlayerPositionTestComponent name={name}/> },

  ];

  const scrimsTabs = [
    { title: "Player stats", id: 'playerPage', component: <PlayerPageScrim name={name} /> },
    { title: "Wards", id: 'playerWardsScrims', component: <PlayerHeatmapComponent name={name} gameType={"Scrims"}/> },
  ];

  const soloQTabs = [
    { title: "Player stats", id: 'playerPage', component: <PlayerSoloqPageComponent name={name} /> },
  ];


  useEffect(() => {
    const teamName = name.split(" ")[0];
    const [start, end] = currentSeasonTime.split(" - ").map(date => date.trim());
    axiosInstance.get(`teammates/?name=${encodeURIComponent(teamName)}`+ '&start=' + start + "&end=" + end)
      .then(response => {
        // Update the team mates data state
        const sortedData = response.data.sort((a, b) => a.Role - b.Role);
        setTeamMatesData(sortedData);
      })
      .catch(error => {
        console.error(error);
      });

 },  [name, currentSeasonTime]);


  const handleBigTabClick = (tabId) => {
    setActiveBigTab(tabId);
    setActiveSubTab('playerPage');
  };

  const handleSubTabClick = (tabId) => {
    setActiveSubTab(tabId);
  };

  const tabs = activeBigTab === 'officials' ? officialsTabs : ("scrims" === activeBigTab ? scrimsTabs: soloQTabs );

  return (
    <div>
      <div style={{ width: "100%", textAlign: "center", color: "white" }}>
        Scrims and Officials
      </div>
      <div style={{display: 'flex', width: "100%", justifyContent: 'center', color: 'black', backgroundColor: "white", marginBottom: "10px", alignItems: "center", textAlign: "center",borderBottom: "2px solid rgb(0, 242, 255)" , borderTop: "2px solid rgb(0, 242, 255)" }}>
        {teamMatesData && (
          <div className="teammates-container">
            <div  style={{alignItems: "center", display: 'flex', justifyContent: 'center'}}  >
              <a
                key={name.split(" ")[0]}
                href={`/team/${name.split(" ")[0]}`}
                style={{textDecoration: "None"}}
                >
                  <div style={{marginTop: "10px", marginBottom: "10px"}}  className="teammate">
                    <img
                      src={TeamIcons[name.split(" ")[0]]}
                      alt={`Role Icon`}
                      className="small-image"
                      />
                    <span className="teammate-name-2" style={{color: 'black'}}>{name.split(" ")[0]}</span>
                  </div>
                </a>
              {teamMatesData.map(teammate => (
                <a
                key={teammate.summonerName}
                href={`/player/${encodeURIComponent(teammate.summonerName)}`}
                style={{textDecoration: "None"}}
                >
                  <div style={{marginTop: "10px", marginBottom: "10px", fontWeight: teammate.summonerName === name ? "bold": "normal"}} className="teammate">
                    <img
                      src={roleIcon[teammate.Role]}
                      alt={`Role ${teammate.Role} Icon`}
                      className="role-icon"

                      />
                    <span className="teammate-name-2" style={{color: 'black'}}>{teammate.summonerName}</span>
                  </div>
                </a>
              ))}
            </div>
          </div>
        )}
      </div>
      <div style={{ display: 'flex',  width: "100%", justifyContent: 'space-between'}}>
        {Bigtabs.map((tab) => (
          <button
            key={tab.id}
            style={{
              backgroundColor: activeBigTab === tab.id ? '#5F5B5B' : " var(--sidebar-color)",
              color: 'white',
              border: '1px solid rgb(0, 242, 255)',
              borderBottom: activeBigTab === tab.id  ?  "none": '1px solid rgb(0, 242, 255)',

              padding: '10px 20px',
              borderTopLeftRadius: "20px",
              borderTopRightRadius: "20px",
              cursor: 'pointer',
              width: `${100 / Bigtabs.length - 1}%`,
            }}
            onClick={() => handleBigTabClick(tab.id)}
          >
            {tab.title}
          </button>
        ))}
      </div>

      <div style={{ display: 'flex', width: "100%", justifyContent: 'space-between', backgroundColor: '#5F5B5B', paddingTop: "10px" }}>
        {tabs.map((tab) => (
          <button
            key={tab.id}
            style={{
              backgroundColor: activeSubTab === tab.id ? '#949191' : '#5F5B5B',
              color: 'white',
              border: '1px solid rgb(0, 242, 255)',
              padding: '5px 10px',
              cursor: 'pointer',
              borderTopLeftRadius: "20px",
              borderTopRightRadius: "20px",
              borderBottom: "none",

              width: `${100 / tabs.length-1}%`,
            }}
            onClick={() => handleSubTabClick(tab.id)}
          >
            {tab.title}
          </button>
        ))}
      </div>

      <div>
        {tabs.find((tab) => tab.id === activeSubTab)?.component}
      </div>
    </div>
  );
};

export default MasterPlayerComponent;
