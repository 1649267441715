import Player from '../../Icons/soccer-player.png';
import Team from "../../Icons/group.png";
import Home from "../../Icons/home.png";
import Login from "../../Icons/password.png";
import Calendar from "../../Icons/calendar.png";
import MatchHistory from "../../Icons/table.png";
import Drafts from "../../Icons/round-table.png";
import Computer from "../../Icons/computer.png";
import History from "../../Icons/history.png";
import Security from "../../Icons/security.png";
import Gear from "../../Icons/gear.png";
import Ward from "../../Icons/ward.webp";
import champion from "../../Icons/plot.png";
import mapka from "../../Icons/map.png";
import human from "../../Icons/human.png";
import opgg from "../../Icons/opgg.png";
import deeplol from "../../Icons/deeplol.png";

const iconDictionary = {
    deeplol,
    opgg,
    human,
    mapka,
    Ward,
    Gear,
    Player: Player,
    Team: Team,
    Home: Home,
    Login: Login,
    Calendar: Calendar,
    MatchHistory: MatchHistory,
    Drafts: Drafts,
    Computer: Computer,
    History: History,
    Security: Security,
    champion
};

export default  iconDictionary;