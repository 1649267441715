import React, { useEffect, useState, useRef } from 'react';
import { useSeason } from './SeasonContext';
import mapPhotsDictionary from './data/MapData';
import Slider from 'rc-slider';
import { TeamIcons } from './data/TeamIcons';
import roleIcon from './data/Role';
import { championData, championPhotos } from './data/ChampionsIcon';
import { useParams } from 'react-router-dom';
import axiosInstance from './backend/axiosInstance';


function renderChampions(game, firstChampion, secondChampion, thirdChampion, fourthChampion, fifthChampion, isBlueTeam) {
  const teamOrderDict = isBlueTeam ? {
      [firstChampion]: "BP1",
      [secondChampion]: "BP2",
      [thirdChampion]: "BP3",
      [fourthChampion]: "BP4",
      [fifthChampion]: "BP5",
  } : {
      [firstChampion]: "RP1",
      [secondChampion]: "RP2",
      [thirdChampion]: "RP3",
      [fourthChampion]: "RP4",
      [fifthChampion]: "RP5",
  };

  return [0, 1, 2, 3, 4].map(index => {
      const championKey = teamOrderDict[index];
      return (
          <div key={index} style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', margin: isBlueTeam ? '0 5px 0 0' : '0 0 0 5px' }}>
              <img src={roleIcon[index]} alt={""} style={{ width: "15px" }} />
              <img
                  src={championPhotos[championData[game[championKey]]]}
                  alt={""}
                  className="small-image"
              />
          </div>
      );
  });
}

const PlayerPositionTestComponent = () => {
  const { currentSeasonTime } = useSeason();

  document.title = "Player Position Test";
  const { name: initialName } = useParams(); // Initial name from URL params
  const [name, setName] = useState(initialName); // State for selected name
  const [matchData, setMatchData] = useState([]);
  const [selectedGame, setSelectedGame] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const [playerData, setPlayerData] = useState([]);
  const [playerPositionData, setPlayerPositionData] = useState([]);
  const [timeSpentInZones, setTimeSpentInZones] = useState({
    green: 0,
    red: 0,
    purple: 0,
    blue: 0,
    yellow: 0,
  });

  const [forwardPercentagesByZone, setForwardPercentagesByZone] = useState({
    green: 0,
    red: 0,
    purple: 0,
    blue: 0,
    yellow: 0,
  });

  const colorDict = {
    green: "Toplane",
    red: "Jungle",
    purple: "Midlane",
    blue: "Botlane",
    yellow: "Base",

  }
  const [forwardPercentage, setForwardPercentage] = useState(0); // Forward %
  const [timeRange, setTimeRange] = useState([0, 0]); // [start, end] time
  const [maxTime, setMaxTime] = useState(0);
  const [proximityData, setProximityData] = useState([]);

  const [currentIndex, setCurrentIndex] = useState();

  const sliderTimeout = useRef(null);
  const mapWidth = 500;
  const mapHeight = 500;
  const minX = -120;
  const minY = -120;
  const maxX = 14970;
  const maxY = 14980;

  const translateCoordinates = (x, y) => {
    const newX = ((x - minX) / (maxX - minX)) * mapWidth;
    const newY = ((maxY - y) / (maxY - minY)) * mapHeight;
    return { newX, newY };
  };

  const isInsideRectangle = (newX, newY, rect) => {
    return (
      newX >= rect.left &&
      newX <= rect.left + rect.width &&
      newY >= rect.top &&
      newY <= rect.top + rect.height
    );
  };

  const isInsideCircle = (newX, newY, circle) => {
    const centerX = circle.left + circle.width / 2;
    const centerY = circle.top + circle.height / 2;
    const radius = circle.width / 2;

    const distance = Math.sqrt((newX - centerX) ** 2 + (newY - centerY) ** 2);
    return distance <= radius;
  };

  const getColor = (newX, newY) => {
    for (let shape of shapes) {
      if (shape.borderRadius === '100%') {
        if (isInsideCircle(newX, newY, shape)) {
          return shape.color;
        }
      } else {
        if (isInsideRectangle(newX, newY, shape)) {
          return shape.color;
        }
      }
    }

    return 'red'; // Default color if no match
  };

  const calculateForwardPercentage = (positions) => {
    let forwardCount = 0;
    let totalCount = 0;

    positions.forEach((position) => {
      const { newX, newY } = translateCoordinates(position.x, position.y);

      if (position.side === 'blue' && newY < newX) {
        forwardCount++;
      } else if (position.side === 'red' && newY > newX) {
        forwardCount++;
      }

      totalCount++;
    });

    if (totalCount === 0) return 0; // To avoid division by zero

    return (forwardCount / totalCount) * 100;
  };

  const calculateForwardPercentageByZone = (filteredPositions) => {
    const forwardByZone = {
      green: { forward: 0, total: 0 },
      red: { forward: 0, total: 0 },
      purple: { forward: 0, total: 0 },
      blue: { forward: 0, total: 0 },
      yellow: { forward: 0, total: 0 },
    };

    filteredPositions.forEach((position) => {
      const { newX, newY } = translateCoordinates(position.x, position.y);
      const color = getColor(newX, newY);

      if (position.side === 'blue' && newY < newX) {
        forwardByZone[color].forward++;
      } else if (position.side === 'red' && newY > newX) {
        forwardByZone[color].forward++;
      }

      forwardByZone[color].total++;
    });

    const forwardPercentages = {};
    Object.keys(forwardByZone).forEach(zone => {
      const { forward, total } = forwardByZone[zone];
      forwardPercentages[zone] = total === 0 ? 0 : (forward / total) * 100;
    });

    return forwardPercentages;
  };

  const calculateProximity = (filteredPositions, summonerName, timeRange, playerData) => {
    const proximityResults = {};


    // Filter chosen player's positions based on summoner name and time range

    const chosenPlayerPositions = filteredPositions.filter(pos =>
      pos.summonerName === summonerName && pos.time >= timeRange[0] && pos.time <= timeRange[1]
    );

    playerData.forEach(player => {
      if (player.summonerName !== summonerName) {
        let proximityCount = 0;

        chosenPlayerPositions.forEach(chosenPosition => {
          const otherPositionAtSameTime = filteredPositions.find(pos => pos.summonerName === player.summonerName && pos.time === chosenPosition.time);

          if (otherPositionAtSameTime) {
            const distance = Math.sqrt((chosenPosition.x - otherPositionAtSameTime.x) ** 2 + (chosenPosition.y - otherPositionAtSameTime.y) ** 2);

            if (distance <= 2000) {
              proximityCount++;
            }
          }
        });

        const totalTimestamps = chosenPlayerPositions.length;
        const proximityPercentage = ((proximityCount / totalTimestamps) * 100).toFixed(2);
        proximityResults[player.summonerName] = proximityPercentage;
      }
    });

    let teammateProximityCount = 0;

    chosenPlayerPositions.forEach(chosenPosition => {
      let isNearTeammate = false;

      playerData.forEach(player => {
        if (player.summonerName !== summonerName && player.teamName === playerData.find(p => p.summonerName === summonerName).teamName) {

          const otherPositionAtSameTime = filteredPositions.find(pos =>
            pos.summonerName === player.summonerName && pos.time === chosenPosition.time
          );

          if (otherPositionAtSameTime) {
            const distance = Math.sqrt(
              (chosenPosition.x - otherPositionAtSameTime.x) ** 2 + (chosenPosition.y - otherPositionAtSameTime.y) ** 2
            );

            if (distance <= 2000) {
              isNearTeammate = true;
            }
          }
        }
      });

      if (isNearTeammate) {
        teammateProximityCount++;
      }
    });

    const totalTimestamps = chosenPlayerPositions.length;
    const teammateProximityPercentage = ((teammateProximityCount / totalTimestamps) * 100).toFixed(2);

    proximityResults[summonerName] = teammateProximityPercentage;

    console.log(proximityResults, chosenPlayerPositions.length, timeRange, chosenPlayerPositions);
    return proximityResults;
  };

  useEffect(() => {
    const [start, end] = currentSeasonTime.split(" - ").map(date => date.trim());
    axiosInstance.get('officials_for_player/?start=' + start + "&end=" + end + '&player_name=' + encodeURIComponent(name))
    .then((response) => {
      const sortedMatchData = response.data.sort((a, b) => new Date(b.date) - new Date(a.date));
      setMatchData(sortedMatchData);
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
    });
    }, [currentSeasonTime, name]);

  const handleGameSelect = (index) => {
    setSelectedGame(matchData[index]);
    setDropdownOpen(false); // Close dropdown after selection
    setCurrentIndex(index)
    fetchData(matchData[index].gameId); // Fetch ward data for the selected game
    };

    const goToPreviousGame = () => {
      if (currentIndex > 0) {
          handleGameSelect(currentIndex - 1);
      }
    };

  const goToNextGame = () => {
      if (currentIndex < matchData.length - 1) {
          handleGameSelect(currentIndex + 1);
      }
    };

  const calculateValues = () => {
    const zoneTimes = { green: 0, red: 0, purple: 0, blue: 0, yellow: 0 };

    const filteredPositions = playerPositionData.filter(
      (position) => position.time >= timeRange[0] && position.time <= timeRange[1] && position.summonerName === name
    );

    filteredPositions.forEach((position) => {
      const { newX, newY } = translateCoordinates(position.x, position.y);
      const color = getColor(newX, newY);

      zoneTimes[color] = (zoneTimes[color] || 0) + 1; // Assuming 1 second per position
    });

    setTimeSpentInZones(zoneTimes);

    const forwardPercentage = calculateForwardPercentage(filteredPositions);
    setForwardPercentage(forwardPercentage);

    const forwardPercentagesByZone = calculateForwardPercentageByZone(filteredPositions);
    setForwardPercentagesByZone(forwardPercentagesByZone);

    // Calculate proximity or any other required value here
    const proximityResults = calculateProximity(playerPositionData, name, timeRange, playerData);
    setProximityData(proximityResults);
  };

  const shapes = [
    { color: "purple", top: 130, left: 315, width: 60, height: 60, borderRadius: "100%" }, // Circle
    { color: "purple", top: 160, left: 285, width: 60, height: 60, borderRadius: "100%" }, // Circle
    { color: "purple", top: 190, left: 255, width: 60, height: 60, borderRadius: "100%" }, // Circle
    { color: "purple", top: 200, left: 200, width: 100, height: 100, borderRadius: "100%" }, // Circle
    { color: "purple", top: 250, left: 185, width: 60, height: 60, borderRadius: "100%" }, // Circle
    { color: "purple", top: 280, left: 155, width: 60, height: 60, borderRadius: "100%" }, // Circle
    { color: "purple", top: 310, left: 125, width: 60, height: 60, borderRadius: "100%" }, // Circle

    { color: "yellow", top: -200, left: 300, width: 400, height: 400, borderRadius: "100%" }, // Quarter Circle
    { color: "yellow", top: 300, left: -200, width: 400, height: 400, borderRadius: "100%" }, // Quarter Circle

    { color: "green", top: 25, left: 25, width: 100, height: 100, borderRadius: "100%" }, // Circle
    { color: "green", top: 100, left: 25, width: 35, height: 210 }, // Vertical Rectangle
    { color: "green", top: 25, left: 100, width: 210, height: 40 }, // Horizontal Rectangle

    { color: "blue", top: 440, left: 190, width: 210, height: 35 }, // Horizontal Rectangle
    { color: "blue", top: 190, left: 440, width: 35, height: 210 },  // Vertical Rectangle
    { color: "blue", top: 375, left: 375, width: 100, height: 100, borderRadius: "100%" }, // Circle
  ];

  const handleTimeRangeChange = (value) => {
    setTimeRange(value);
  }
  // trvohe moze poprostu dodać przycisk aby to liczyć ????????

  const fetchData = (gameId) => {
    axiosInstance.get(`playerposition/?gameId=${gameId}`)
      .then(response => {
        setPlayerPositionData(response.data);
        setMaxTime(Math.max(...response.data.map(pos => pos.time))); // Set max time based on data
        setTimeRange([0, Math.max(...response.data.map(pos => pos.time))]); // Initialize time range to full data
      })
      .catch(error => {
        console.error(error);
      });

      axiosInstance.get(`players/?gameId=${gameId}&info=1`)
      .then(response => {
        setPlayerData(response.data);
      })
      .catch(error => {
        console.error(error);
      });
  };

  const handleTimeChange2 = (e, index) => {
    const [minutes, seconds] = e.target.value.split(':').map(Number);
    const timeInSeconds = (minutes * 60) + seconds;

    if (!isNaN(timeInSeconds) && timeInSeconds >= 0 && timeInSeconds <= maxTime) {
      const newTimeRange = [...timeRange];
      newTimeRange[index] = timeInSeconds;
      setTimeRange(newTimeRange);
    }
  };

  const handlePlayerSelect = (event) => {
    setName(event.target.value); // Update name based on selection
  };

  const totalTime = Object.values(timeSpentInZones).reduce((sum, time) => sum + time, 0);
  console.log(timeRange)

  return (
    <div>
      <div className='filters' style={{ textAlign: 'center', marginBottom: '20px', color: "black" }}>
      <div style={{ marginTop: '10px' }}>
          <button
            className='button-top-page'
            onClick={goToPreviousGame}
            disabled={currentIndex === 0}
            style={{ marginRight: '15px', padding: '5px 10px' , width: "120px" }}
          >
            {"<-"}Previous
          </button>
        </div>


            <div
                onClick={() => setDropdownOpen(!dropdownOpen)}
                style={{
                    border: '1px solid #ccc',
                    cursor: 'pointer',
                    display: 'inline-block',
                    width: '700px',
                    height: '67px',
                    backgroundColor: 'white',
                    position: 'relative',
                }}
            >
                {selectedGame ?  (
                <div style={{
                    border: '1px solid #ccc',
                    position: 'absolute',
                    backgroundColor: 'white',
                    zIndex: 100,
                    width: '700px',
                    maxHeight: '400px',
                    overflowY: 'auto'
                }}>
                        <div
                            key={selectedGame.gameId}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                cursor: 'pointer',
                                borderBottom: '1px solid #ccc',
                                padding: "10px"
                            }}
                        >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div style={{width: "120px"}}>
                                    {selectedGame.leagueName} {selectedGame.patch}
                                </div>
                                <div style={{display: 'flex'}}>
                                    {renderChampions(selectedGame, selectedGame.B1R, selectedGame.B2R, selectedGame.B3R, selectedGame.B4R, selectedGame.B5R, true)}
                                    <div style={{ display: 'flex', alignItems: 'center', margin: '0 10px' }}>
                                        <img className="small-image" src={TeamIcons[selectedGame.team1]} alt={selectedGame.team1} style={{ width: '25px', marginRight: '5px' }} />
                                        {selectedGame.team1} vs {selectedGame.team2}
                                        <img className="small-image" src={TeamIcons[selectedGame.team2]} alt={selectedGame.team2} style={{ width: '25px', marginLeft: '5px' }} />
                                    </div>
                                    {renderChampions(selectedGame, selectedGame.R1R, selectedGame.R2R, selectedGame.R3R, selectedGame.R4R, selectedGame.R5R, false)}
                                </div>
                            </div>
                        </div>
                </div>
            ) : 'Select a Game'}

                {dropdownOpen && (
                    <div
                        style={{
                            border: '1px solid #ccc',
                            position: 'absolute',
                            backgroundColor: 'white',
                            zIndex: 100,
                            width: '700px',
                            maxHeight: '400px',
                            overflowY: 'auto',
                            top: '67px',
                        }}
                    >
                        {matchData.map((game, index) => {
                            return (
                                <div
                                    key={game.gameId}
                                    onClick={() => handleGameSelect(index)}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        padding: '10px',
                                        cursor: 'pointer',
                                        borderBottom: '1px solid #ccc',
                                        transition: 'background 0.3s',
                                    }}
                                    onMouseEnter={(e) => e.currentTarget.style.background = '#f0f0f0'}
                                    onMouseLeave={(e) => e.currentTarget.style.background = 'white'}
                                >
                                    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                        <div style={{ width: '120px', textAlign: 'center' }}>
                                            {game.leagueName} {game.patch}
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', width: '100%' }}>
                                            <div style={{ display: 'flex' }}>
                                            {renderChampions(game, game.B1R, game.B2R, game.B3R, game.B4R, game.B5R, true)}
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center', margin: '0 10px' }}>
                                                <img className="small-image" src={TeamIcons[game.team1]} alt={`Team ${game.team1}`} style={{ width: '35px', marginRight: '10px' }} />
                                                <span style={{ fontWeight: 'bold' }}>{game.team1} vs {game.team2}</span>
                                                <img className="small-image" src={TeamIcons[game.team2]} alt={`Team ${game.team2}`} style={{ width: '35px', marginLeft: '10px' }} />
                                            </div>
                                            <div style={{ display: 'flex' }}>
                                            {renderChampions(game, game.R1R, game.R2R, game.R3R, game.R4R, game.R5R, false)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>

            <div style={{ marginTop: '10px' }}>
                <button
                className='button-top-page'
                onClick={goToNextGame}
                disabled={currentIndex === matchData.length - 1}
                style={{ marginLeft: '15px', padding: '5px 10px', width: "120px" }}
                >
                    Next {"->"}
                </button>
            </div>

      </div>

      <div style={{ position: 'relative', display: 'flex' }} >
        <img src={mapPhotsDictionary["SummonersRift"]} alt='Summoner’s Rift' style={{ width: "500px", height: "500px" }} />

        {playerPositionData.filter(pos => pos.time >= timeRange[0] && pos.time <= timeRange[1] && pos.summonerName === name)
          .map((position, index) => {
            const { newX, newY } = translateCoordinates(position.x, position.y);
            const color = getColor(newX, newY);

            return (
              <div
                key={index}
                style={{
                  position: 'absolute',
                  top: newY + 'px',
                  left: newX + 'px',
                  width: '5px',
                  height: '5px',
                  backgroundColor: color,
                  borderRadius: '50%',
                }}
              ></div>
            );
          })}

        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '707px',
            height: '2px',
            borderTop: '2px solid white',  // Creates the diagonal line
            transform: 'rotate(45deg)',
            transformOrigin: '0 0',
          }}
        />
        <div style={{ color: 'white', width: "100%", display: 'flex', paddingTop: "20px", flexDirection: 'column'  }}>
          <div style={{ display: 'flex', justifyContent: 'center', width: "100%", flexDirection: "column" }}>
            <div style={{ marginBottom: '20px', display: 'flex', justifyContent: 'center', width: "100%" }}>
              <label>Player: </label>
              <select onChange={handlePlayerSelect} value={name} defaultValue={initialName}>
                <option key={initialName} value={initialName}>
                    {initialName}
                </option>
                {playerData.filter((player) => player.summonerName != initialName).map(player => (
                  <option key={player.summonerName} value={player.summonerName}>
                    {player.summonerName}
                  </option>
                ))}
              </select>
            </div>
            <div style={{width: "100%", display: 'flex', justifyContent: 'center'}}>
              <Slider
                range
                min={0}
                max={maxTime}
                step={60}  // 1 minute steps
                value={timeRange}
                onChange={handleTimeRangeChange}
                tooltip={{ formatter: (value) => `${Math.floor(value / 60)}:${String(value % 60).padStart(2, '0')}` }}  // Tooltip format for minutes and seconds
                style={{
                  width: '80%',
                  padding: '10px',
                  border: 'none',
                  borderRadius: '5px',
                  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
                }}
                />
            </div>
            <div style={{display: 'flex', justifyContent: 'space-around'}}>
              <div>
                <input
                  type="text"
                  value={`${Math.floor(timeRange[0] / 60)}:${String(timeRange[0] % 60).padStart(2, '0')}`}
                  onChange={(e) => handleTimeChange2(e, 0)}
                  placeholder="MM:SS"
                  style={{ width: "60px", marginRight: "10px" }}
                  />
                <label> - </label>
                <input
                  type="text"
                  value={`${Math.floor(timeRange[1] / 60)}:${String(timeRange[1] % 60).padStart(2, '0')}`}
                  onChange={(e) => handleTimeChange2(e, 1)}
                  placeholder="MM:SS"
                  style={{ width: "60px", marginLeft: "10px" }}
                  />
              </div>
              <button className='button-top-page' style={{width: "30%"}} onClick={() => calculateValues()}>Calculate</button> {/* Open ROFL link */}
            </div>
          </div>

          <div style={{width: "100%", display: 'flex', marginTop: "40px"}}>
            <div style={{width: "50%", padding: "10px"}}>
              <h3>Time Spent in Each Zone</h3>
              <table style={{backgroundColor: 'white', color: 'black'}}>
                    <thead>
                      <tr>
                        <th> Place</th>
                        <th> %</th>
                        <th> Forward%</th>
                      </tr>
                    </thead>
                    <tbody>
                {Object.entries(timeSpentInZones).map(([zone, time]) => (
                  <tr>
                        <td>
                    {colorDict[zone]}:
                        </td>
                        <td>
                        {totalTime === 0 ? '0%' : ((time / totalTime) * 100).toFixed(2) + '%'}
                        </td>
                        <td>
                        {forwardPercentagesByZone[zone].toFixed(2)}%
                        </td>
                      </tr>
                ))}
                </tbody>
              </table>
            </div>

            <div style={{width: "50%", padding: "10px"}}>
              <h3>Proximity</h3>

              { playerData && selectedGame ? (


                <div style={{display: 'flex'}}>
                <div style={{width: "50%"}}>
                  <table style={{backgroundColor: 'white', color: 'black'}}>
                    <thead>
                      <tr>
                        <th> {selectedGame.team1}</th>
                        <th> %</th>

                      </tr>
                    </thead>
                    <tbody>

                    {playerData && playerData.sort((a, b) => a.Role - b.Role).filter((player) => selectedGame.team1 == player.teamName).map(player => (
                      <tr>
                        <td style={{backgroundColor: player.summonerName === name ? "darkblue": "transparent"}}>
                          {player.summonerName}
                        </td>
                        <td>
                          {proximityData[player.summonerName]}%
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  </table>
                </div>
                <div style={{width: "50%"}}>
                  <table style={{backgroundColor: 'white', color: 'black'}}>
                    <thead>
                      <tr>
                        <th> {selectedGame.team2}</th>
                        <th> %</th>

                      </tr>
                    </thead>
                    <tbody>

                    {playerData && playerData.sort((a, b) => a.Role - b.Role).filter((player) => selectedGame.team2 == player.teamName).map(player => (
                      <tr>
                        <td style={{backgroundColor: player.summonerName === name ? "darkblue": "transparent"}}>
                          {player.summonerName}
                        </td>
                        <td>
                          {proximityData[player.summonerName]}%
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  </table>
                </div>
              </div>
              ): (
                <></>
              )}
            </div>
          </div>
          <h6>Forward Percentage: {forwardPercentage.toFixed(2)}%</h6>
        </div>
      </div>
    </div>
  );
};

export default PlayerPositionTestComponent;
